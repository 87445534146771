import { type FetchOptions } from "ofetch";
import { type AsyncDataOptions } from "#app";

import { v4 as uid } from "uuid";

// locals
import FetchFactory from "../factory";

type IAuth = {
  expires_at: 0;
  data: null | {
    access_token: string;
    refresh_token: string;
    expires_at: 0;
  };
  errors: string[];
  status: string | null;
  error: {
    data: null;
    errors: string[];
    status: string;
  };
};

// https://api.dev.useflik.com/checkout/v1/platform/cart/f63a6b30-245f-46cf-a54a-32f9ac30ab2d

class AuthModule extends FetchFactory<IAuth> {
  [x: string]: any;

  otpSend(payload: any, asyncDataOptions?: AsyncDataOptions<IAuth>) {
    const { AUTHSVC } = useRuntimeConfig().public;
    console.log("modules otpSend UTHSVC:", AUTHSVC);
    const { APP_ID } = useRuntimeConfig().app;
    const appId = useRuntimeConfig().appId;
    console.log("appId:", appId);
    console.log("otpSend APP_ID:", APP_ID);
    const request_id = uid();

    return useAsyncData(async () => {
      const fetchOptions: FetchOptions<"json"> = {
        headers: {
          "X-App-ID": APP_ID,
          "X-Request-ID": request_id,
        },
      };
      Object.assign(payload, {
        device_id: request_id,
        app_id: APP_ID,
      });
      return await this.call(
        "POST",
        `${AUTHSVC}/v1/authentication/otp/send`,
        payload,
        fetchOptions,
      ).catch((err) => err);
    }, asyncDataOptions);
  }

  otpValidate(payload: any, asyncDataOptions?: AsyncDataOptions<IAuth>) {
    const { AUTHSVC } = useRuntimeConfig().public;
    const { APP_ID } = useRuntimeConfig().app;
    const request_id = uid();

    Object.assign(payload, {
      device_id: request_id,
      app_id: APP_ID,
    });

    return useAsyncData(async () => {
      const fetchOptions: FetchOptions<"json"> = {
        headers: {
          "X-App-ID": APP_ID,
          "X-Request-ID": request_id,
        },
      };
      return await this.call(
        "POST",
        `${AUTHSVC}/v1/authentication/otp/validate`,
        payload,
        fetchOptions,
      );
    }, asyncDataOptions);
  }
}

export default AuthModule;
