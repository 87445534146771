// 3rd's
import { $fetch, type FetchOptions } from "ofetch";

// locals
import ProductsModule from "~/repository/modules/products";
import AuthModule from "~/repository/modules/auth";
import CartModule from "~/repository/modules/cart";
import ShopperModule from "~/repository/modules/shopper";
import ShippingModule from "~/repository/modules/shipping";
import PaymentModule from "~/repository/modules/payment";
// import PromoModule from "~/repository/modules/promo";
import TransactionModule from "~/repository/modules/transaction";

interface IApiInstance {
  auth: AuthModule;
  cart: CartModule;
  shopper: ShopperModule;
  product: ProductsModule;
  payment: PaymentModule;
  shipping: ShippingModule;
  // promo: PromoModule;
  transaction: TransactionModule;
}

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();

  const fetchOptions: FetchOptions = {
    baseURL: config.public.API_URL,
  };

  // Create a new instance of $fecther with custom option
  const apiFecther = $fetch.create(fetchOptions);

  // An object containing all repositories we need to expose
  const modules: IApiInstance = {
    product: new ProductsModule(apiFecther),
    auth: new AuthModule(apiFecther),
    cart: new CartModule(apiFecther),
    shopper: new ShopperModule(apiFecther),
    payment: new PaymentModule(apiFecther),
    shipping: new ShippingModule(apiFecther),
    // promo: new PromoModule(apiFecther),
    transaction: new TransactionModule(apiFecther),
  };

  return {
    provide: {
      api: modules,
    },
  };
});
