import { type FetchOptions } from "ofetch";
import { type AsyncDataOptions } from "#app";

import { v4 as uid } from "uuid";
import HmacSHA256 from "crypto-js/hmac-sha256";

// locals
import FetchFactory from "../factory";

type IShopper = {
  status: string;
  data: null | {
    data: {
      account: Account;
      shipping_address: Array<Address>;
      saved_payments: Array<Payment>;
      default_address: Address;
      default_payment: Payment;
      ewalletBalance: [
        {
          balance: number;
          token_id: string;
        },
      ];
      created_at: number;
    };
    errors: string[];
  };
};

// https://api.dev.useflik.com/checkout/v1/platform/cart/f63a6b30-245f-46cf-a54a-32f9ac30ab2d

class ShopperModule extends FetchFactory<IShopper> {
  [x: string]: any;

  getShopper(id: string, asyncDataOptions?: AsyncDataOptions<IShopper>) {
    const { SHOPPERSVC } = useRuntimeConfig().public;
    const { APP_KEY, APP_SECRET } = useRuntimeConfig().app;
    const request_id = uid();
    const signature = HmacSHA256(request_id + APP_SECRET, APP_KEY).toString();

    const { accessToken, loggedIn } = useAuthStore();
    const authorization = `Bearer ${accessToken}`;
    const headers = {
      "X-sample": id,
      "X-Request-ID": request_id,
      "X-Signature": signature,
      authorization,
    };

    if (loggedIn) Object.assign(headers, { authorization });

    return useAsyncData(async () => {
      const fetchOptions: FetchOptions<"json"> = {
        headers: headers,
      };
      return await this.call(
        "GET",
        `${SHOPPERSVC}/v1/shopper`,
        undefined, // body
        fetchOptions,
      ).catch((err) => err);
    }, asyncDataOptions);
  }

  async putAccount(
    payload: any,
    asyncDataOptions?: AsyncDataOptions<IShopper>,
  ) {
    const { SHOPPERSVC } = useRuntimeConfig().public;
    const { APP_KEY, APP_SECRET } = useRuntimeConfig().app;
    const request_id = uid();
    const signature = HmacSHA256(request_id + APP_SECRET, APP_KEY).toString();

    const { accessToken, loggedIn } = useAuthStore();
    const authorization = `Bearer ${accessToken}`;
    const headers = {
      "X-Request-ID": request_id,
      "X-Signature": signature,
      authorization,
    };

    if (loggedIn) Object.assign(headers, { authorization });

    return await useAsyncData(async () => {
      const fetchOptions: FetchOptions<"json"> = {
        headers: headers,
      };
      return await this.call(
        "PUT",
        `${SHOPPERSVC}/v1/shopper`,
        payload,
        fetchOptions,
      ).catch((err) => err);
    }, asyncDataOptions);
  }

  putAddress(payload: any, asyncDataOptions?: AsyncDataOptions<IShopper>) {
    const { SHOPPERSVC } = useRuntimeConfig().public;
    const { APP_KEY, APP_SECRET } = useRuntimeConfig().app;
    const request_id = uid();
    const signature = HmacSHA256(request_id + APP_SECRET, APP_KEY).toString();

    const { accessToken, loggedIn } = useAuthStore();
    const authorization = `Bearer ${accessToken}`;
    const headers = {
      "X-Request-ID": request_id,
      "X-Signature": signature,
      authorization,
    };

    if (loggedIn) Object.assign(headers, { authorization });

    return useAsyncData(async () => {
      const fetchOptions: FetchOptions<"json"> = {
        headers: headers,
      };
      return await this.call(
        "PUT",
        `${SHOPPERSVC}/v1/shopper/address`,
        payload,
        fetchOptions,
      ).catch((err) => err);
    }, asyncDataOptions);
  }
}

export default ShopperModule;
