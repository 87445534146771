export const defaultCourier = {
  id: "",
  merchant_id: "",
  title: "Kurir",
  courier_name: "",
  courier_service_name: "",
  courier_logo: "https://cdn.useflik.com/image/couriers/custom_courier.png",
  description: "",
  service_type: "",
  shipment_duration_range: "",
  shipment_duration_unit: "",
  price: 0,
  price_discount: 0,
  is_enabled: false,
  discount: false,
  is_free: false,
  discount_price: 0,
  working_hour_info: "",
};

export const defaultAddress = {
  address_id: "",
  address_label: "",
  name: "",
  email: "",
  phone: "",
  status: "",
  type: "",
  address_1: "",
  address_2: "",
  address_3: "",
  address_4: "",
  city: "",
  state_province: "",
  country_code: "",
  postal_code: "",
  address_note: "",
  lat: 0,
  lng: 0,
};

export const emptyAddress = {
  address_id: "",
  address_label: "",
  name: "",
  email: "",
  phone: "",
  status: "",
  type: "",
  address_1: "",
  address_2: "",
  address_3: "",
  address_4: "",
  city: "",
  state_province: "",
  country_code: "",
  postal_code: 0,
  address_note: "",
  lat: 0,
  lng: 0,
};

export const defaultPayment = {
  ewallet_info: null,
  card_info: null,
  dynamic_attr: null,
  brand_name: "",
  checkout_method: "",
  description: "",
  id: "",
  image: "",
  is_enabled: false,
  label_1: "",
  label_2: "",
  order: 0,
  provider: "",
  title: "",
  token_id: "",
  type: "",
};

export const defaultProduct = {
  id: "",
  merchant_prodid: "",
  sku: "",
  name: "",
  description: "",
  status: "",
  variants: [
    {
      name: "",
      options: [],
    },
  ],
  use_variant: true,
  items: [
    {
      varid: "",
      merchant_varid: "",
      sku: "",
      variant: {
        Variation: "",
      },
      varoptions: [""],
      price: {
        base_price: 0,
        sell_price: 0,
        discount_price: 0,
        discount: false,
        currency: "",
        labels: {},
      },
      stock_available: true,
      stock_qty: 10,
      image_url: "",
    },
  ],
  category: "",
  tags: "",
  weight: 1000,
  volume: {
    length: 37,
    width: 5,
    height: 5,
  },
  image_url: "",
  assets: [
    {
      height: 0,
      width: 0,
      image_url: "",
    },
  ],
  merchant: {
    id: "",
    name: "",
    city: "",
    username: "",
    company_name: "",
    logo_url: "",
  },
  stock_type: "",
  stock_available: true,
  stock_qty: 0,
  detail_url: "",
  product_slug: "",
  merchant_slug: "",
  price: {
    base_price: 0,
    sell_price: 0,
    discount_price: 0,
    discount: true,
    currency: "IDR",
    labels: {
      discount_percent: "",
    },
  },
  variant: null,
  created_at: 0,
  created_by: "forstok",
  updated_at: 0,
  updated_by: "forstok",
  version: 1,
  source: "flik.co.id",
  default_warehouse: {
    id: "",
    warehouse_code: "",
    city: "",
    phone: "",
    postal_code: "",
  },
};

export const defaultProductItem = {
  item_id: "f11f8647-e399-4ae8-a0d7-cecfe36fae9c",
  merchant_id: "89c22f6f-213a-4dca-a9cf-b124b55bd098",
  merchant_item_id: "20614965",
  name: "Tamarin Festive",
  qty: 2,
  product_fresh: false,
  unit_price: 28600,
  note: "",
  dynamic_attributes: { Variation: "Candy " },
  weight: 300,
  image_url:
    "https://d3ndwuuxpr6t02.cloudfront.net/system/item/images/images/154/637/960/xlarge/808ffdd1c64abfcfcf74ab00289c523f.jpg?1677467717",
  detail_url: "https://dev.useflik.com/mayora/tamarin-festive||316632",
  product_slug: "tamarin-festive||316632",
  merchant_slug: "mayora",
  stock_available: true,
  is_custom: false,
  is_discount: false,
  use_variant: true,
  sell_price: 28600,
  discount_price: 22000,
  total_amount: 44000,
  subtotal_amount: 44000,
  item_level_discounts: [],
  merchant_name: "Mayora",
  merchant_logo: "https://cdn.useflik.com/image/logos/merchant/mayora.png",
  warehouse_id: "2b624824-c809-4173-852f-3d27a336d0f7",
  wh: {
    id: "2b624824-c809-4173-852f-3d27a336d0f7",
    postal_code: "11730",
    city: "Kota Jakarta Barat",
    lat: 0,
    lng: 0,
  },
  shipment: { courier_id: "", required: true },
  volume: { length: 9, width: 9, height: 15 },
  info: null,
  show_qty: true,
  editable: true,
};

export const defaultInstallments = [
  {
    currency: "IDR",
    service_fee: 0,
    vat_fee: 0,
    total_fee: 0,
    installment_tenure: 0,
    amount_per_interval: 0,
    description: "Full payment",
    installment: null,
  },
  {
    currency: "IDR",
    service_fee: 0,
    vat_fee: 0,
    total_fee: 0,
    installment_tenure: 3,
    amount_per_interval: 43250,
    description: "Some description for 3 month",
    installment: {
      interval: "month",
      tenure: 3,
      bank_acquirer: "BRI",
    },
  },
  {
    currency: "IDR",
    service_fee: 0,
    vat_fee: 0,
    total_fee: 0,
    installment_tenure: 6,
    amount_per_interval: 86500,
    description: "Some description for 6 month",
    installment: {
      interval: "month",
      tenure: 6,
      bank_acquirer: "BRI",
    },
  },
  {
    currency: "IDR",
    service_fee: 0,
    vat_fee: 0,
    total_fee: 0,
    installment_tenure: 12,
    amount_per_interval: 43250,
    description: "Some description for 12 month",
    installment: {
      interval: "month",
      tenure: 12,
      bank_acquirer: "BRI",
    },
  },
];

export const defaultOrder = {
  id: "",
  status: "",
  note: "",
  status_label: "",
  status_description: "",
  status_color: "",
  transaction_id: "",
  payment_status: "",
  merchant: {
    id: "",
    name: "",
    logo: "",
    merchant_url: "",
  },
  subtotal_amount: 0,
  disc_amount: 0,
  disc_amount_applied: 0,
  promo: [],
  tax_amount: 0,
  total_amount: 0,
  shipping_amount: 0,
  payment_info: {
    type: "",
    expiry_date: "",
    promos: null,
    cod_amount: 0,
  },
  shipment: {
    address: {
      address_label: "",
      name: "",
      email: "",
      phone: "",
      address_1: "",
      address_2: "",
      address_3: "",
      address_4: "",
      city: "",
      state_province: "",
      postal_code: "",
      country_code: "",
      country: "",
      address_note: "",
      lat: -6.4073569,
      lng: 106.8173307,
    },
    courier: "",
    awb: "",
  },
  items: [],
  transactional_log: [
    {
      log_time: 1690971527,
      order_status: "created",
      status_description: "",
      status_label: "",
      status_color: "",
      description: "",
    },
  ],
  metadata: {
    store_id: "",
    store_app_id: "",
  },
  redirect_url: "",
};
