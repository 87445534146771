export function amplitudeTrackPageViewByRoute() {
  let pageName;

  const ampKey = useRuntimeConfig().public.AMPLITUDE_API_KEY || "";
  console.log("ampKey:", ampKey);
  if (typeof ampKey === "undefined" || ampKey === "") {
    return;
  }
  if (import.meta.client) {
    const { $amplitude }: any = useNuxtApp();
    const amplitudeStore = useAmplitudeStore();
    const route: any = useRoute();
    const cart = useCartStore();
    const auth = useAuthStore();
    const shopper = useShopperStore();
    const visitorInfo = useVisitorInfoStore();

    const label = `Checkout Page ${auth.loggedIn ? "Authenticated" : "Anonymous"}`;

    amplitudeStore.setDefaultProp(
      "web",
      auth.loggedIn ? shopper.account._id : "anonymous",
      visitorInfo.getVisitorId,
    );
    switch (route.name) {
      case "login":
        // tracking moved into FormLogin component. Since platform login was happened on "cart" route
        break;

      case "otp":
        amplitudeStore.setPropForPageViewed(
          "Checkout OTP Page",
          cart.isGeneralCart ? "General Checkout" : cart.getMerchantName,
          cart.isGeneralCart ? "" : cart.getMerchantName,
        );
        $amplitude.track(
          AmpEventEnum.ampPageViewed.toString(),
          amplitudeStore.eventProps,
        );
        break;

      case "cart":
        amplitudeStore.setPropForPageViewed(
          label,
          cart.isGeneralCart ? "General Checkout" : cart.getMerchantName,
          cart.isGeneralCart ? "" : cart.getMerchantName,
        );
        $amplitude.track(
          AmpEventEnum.ampPageViewed.toString(),
          amplitudeStore.eventProps,
        );
        break;

      case "cart-id":
        amplitudeStore.setPropForPageViewed(
          "Checkout Page",
          cart.isGeneralCart ? "General Checkout" : cart.getMerchantName,
          cart.isGeneralCart ? "" : cart.getMerchantName,
        );
        $amplitude.track(
          AmpEventEnum.ampPageViewed.toString(),
          amplitudeStore.eventProps,
        );
        break;

      case "profile":
        pageName = "Checkout Profile Page";
        if (shopper.account.email === "" && shopper.firstName === "") {
          // assumed it was new shopper with add profile page flow
          pageName = "Checkout Add Profile Page";
        }
        amplitudeStore.setPropForPageViewed(
          pageName,
          cart.isGeneralCart ? "General Checkout" : cart.getMerchantName,
        );
        $amplitude.track(
          AmpEventEnum.ampPageViewed.toString(),
          amplitudeStore.eventProps,
        );
        break;

      default:
        break;
    }
  }
}

export function amplitudeTrackPageView(pageName: string, pageVariant?: string) {
  const ampKey = useRuntimeConfig().public.AMPLITUDE_API_KEY || "";
  if (typeof ampKey === "undefined" || ampKey === "") {
    return;
  }
  if (import.meta.client) {
    const { $amplitude }: any = useNuxtApp();
    const amplitudeStore = useAmplitudeStore();
    const cart = useCartStore();

    if (pageVariant === undefined) {
      pageVariant = cart.isGeneralCart
        ? "General Checkout"
        : cart.getMerchantName;
    }

    amplitudeStore.setPropForPageViewed(pageName, pageVariant);
    $amplitude.track(
      AmpEventEnum.ampPageViewed.toString(),
      amplitudeStore.eventProps,
    );
  }
}

export function amplitudeTrackBtnClicked(
  btnName: string,
  btnVar: string,
  btnSubVar: string,
) {
  const ampKey = useRuntimeConfig().public.AMPLITUDE_API_KEY || "";
  if (typeof ampKey === "undefined" || ampKey === "") {
    return;
  }
  if (import.meta.client) {
    const { $amplitude }: any = useNuxtApp();
    const amplitudeStore = useAmplitudeStore();

    amplitudeStore.setPropForBtnClicked(btnName, btnVar, btnSubVar);
    $amplitude.track(
      AmpEventEnum.ampBtnClicked.toString(),
      amplitudeStore.eventProps,
    );
  }
}

export function amplitudeTrackLoginAndSignup(event: string) {
  const ampKey = useRuntimeConfig().public.AMPLITUDE_API_KEY || "";
  if (typeof ampKey === "undefined" || ampKey === "") {
    return;
  }
  if (import.meta.client) {
    const { $amplitude }: any = useNuxtApp();
    const amplitudeStore = useAmplitudeStore();

    if (event === "login") {
      amplitudeStore.setPropForLoginCompleted();
      $amplitude.track(
        AmpEventEnum.ampLoginCompleted.toString(),
        amplitudeStore.eventProps,
      );
    } else {
      amplitudeStore.setPropForSignupCompleted();
      $amplitude.track(
        AmpEventEnum.ampSignupCompleted.toString(),
        amplitudeStore.eventProps,
      );
    }
  }
}

export function amplitudeTrackCheckoutCompleted() {
  const ampKey = useRuntimeConfig().public.AMPLITUDE_API_KEY || "";
  if (typeof ampKey === "undefined" || ampKey === "") {
    return;
  }
  if (import.meta.client) {
    const { $amplitude }: any = useNuxtApp();
    const amplitudeStore = useAmplitudeStore();
    const cart = useCartStore();
    const trx = useTransactionStore();
    const shopper = useShopperStore();
    const shipping = useShippingStore();

    amplitudeStore.setPropForCheckoutCompleted(
      cart.isGeneralCart ? "General" : "Platform",
      cart.getMerchantName,
      trx.trxId,
      "",
      cart?.subtotal_amount,
      cart?.total_discount_amount,
      cart?.totalOrder,
      shopper?.defPayment?.title,
      shipping?.selectedCourier[0]?.title,
    );
    $amplitude.track(
      AmpEventEnum.ampCheckoutCompleted.toString(),
      amplitudeStore.eventProps,
    );
  }
}

export function amplitudeTrackCheckoutInitiated() {
  const ampKey = useRuntimeConfig().public.AMPLITUDE_API_KEY || "";
  if (typeof ampKey === "undefined" || ampKey === "") {
    return;
  }
  if (import.meta.client) {
    const { $amplitude }: any = useNuxtApp();
    const amplitudeStore = useAmplitudeStore();
    const cart = useCartStore();
    const reqUrl = useRequestURL();
    const checkoutUniqueID = reqUrl.searchParams.get("uqid");

    if (!checkoutUniqueID) {
      return;
    }

    const checkoutType = cart.isInvoiceCart
      ? "Invoice"
      : cart.isPlatformCart
        ? "Platform"
        : "General";
    amplitudeStore.setPropForCheckoutInitiated(
      checkoutType,
      cart.getMerchantName,
      cart.total_amount,
    );
    $amplitude.track({
      event_type: AmpEventEnum.ampCheckoutInit.toString(),
      event_properties: amplitudeStore.eventProps,
      insert_id: checkoutUniqueID,
    });
  }
}

export function amplitudeGetClient(): any {
  const { $amplitude } = useNuxtApp();
  return $amplitude;
}
