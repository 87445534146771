import { defineStore } from "pinia";

export const useVisitorInfoStore = defineStore("visitorInfo", {
  state: () => ({
    visitorId: "",
    browserName: "",
    browserVersion: "",
    browseEngine: "",
    deviceVendor: "",
    deviceModel: "",
    deviceType: "",
    osName: "",
    osVersion: "",
    cpu: "",
    userAgent: "",
  }),

  actions: {
    setBrowserInfo(params: any) {
      this.browserName = params.browserName;
      this.browserVersion = params.browserVersion;
      this.browseEngine = params.browseEngine;
      this.deviceVendor = params.deviceVendor;
      this.deviceModel = params.deviceModel;
      this.deviceType = params.deviceType;
      this.osName = params.osName;
      this.osVersion = params.osVersion;
      this.cpu = params.cpu;
      this.userAgent = params.userAgent;
    },
    setVisitorId(id: string) {
      this.visitorId = id;
    },
  },

  getters: {
    getVisitorId(): string {
      return this.visitorId;
    },
    getUserAgent(): string {
      return this.userAgent;
    },
  },
});
