import { type FetchOptions } from "ofetch";
import { type AsyncDataOptions } from "#app";

import { v4 as uid } from "uuid";

// locals
import FetchFactory from "../factory";

class ShippingModule extends FetchFactory<ApiResponse> {
  [x: string]: any;

  async getRates(
    payload: any,
    asyncDataOptions?: AsyncDataOptions<ApiResponse>,
  ) {
    const { SHIPPINGSVC } = useRuntimeConfig().public;
    const request_id = uid();

    const { accessToken } = useAuthStore();
    const authorization = `Bearer ${accessToken}`;
    const headers = {
      "X-Request-ID": request_id,
      authorization,
    };

    return await useAsyncData(async () => {
      const fetchOptions: FetchOptions<"json"> = {
        headers: headers,
      };
      return await this.call(
        "POST",
        `${SHIPPINGSVC}/v1/rates/merchant`,
        payload, // body
        fetchOptions,
      ).catch((err) => err);
    }, asyncDataOptions);
  }
}

export default ShippingModule;
