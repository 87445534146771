import type { UseFetchOptions } from "#app";
import { defu } from "defu";
import { v4 as uid } from "uuid";
// import HmacSHA256 from "crypto-js/hmac-sha256";

export function useFlikApi<T>(
  url: string | (() => string),
  options: UseFetchOptions<T> = {},
) {
  const request_id = uid();
  const { accessToken, loggedIn } = useAuthStore();
  const authorization = `Bearer ${accessToken}`;

  const headers = {
    "X-Request-ID": request_id,
  };

  if (loggedIn) Object.assign(headers, { authorization });

  const { API_URL } = useRuntimeConfig().public;

  const defaults: UseFetchOptions<T> = {
    baseURL: API_URL ?? "https://api.dev.useflik.com",
    // this overrides the default key generation, which includes a hash of
    // url, method, headers, etc. - this should be used with care as the key
    // is how Nuxt decides how responses should be deduplicated between
    // client and server
    key: url,
    // set user token if connected
    headers: headers,
    onResponse(ctx) {
      console.log("onResponse ctx:", ctx);
      // _ctx.response._data = new myBusinessResponse(_ctx.response._data)
    },

    onResponseError(ctx) {
      console.log("onResponseError ctx:", ctx);

      // throw new myBusinessError()
    },
  };

  // for nice deep defaults, please use unjs/defu
  const params = defu(options, defaults);

  return useFetch(url, params);
}
