<script setup lang="ts">
import type { NuxtError } from "#app";
const ui = useUiStore();
// const router = useRouter();
const props = defineProps({
  // eslint-disable-next-line vue/require-default-prop
  error: Object as () => NuxtError,
});
console.log("props:", props.error);

const backToHome = () => {
  console.log("backToHome ui.homePath:", ui.homePath);
  console.log("window.location", window.location);
  location.href = window.location.origin + ui.homePath;
  // return navigateTo({ path: ui.homePath, replace: true });
};
</script>

<template>
  <NuxtLayout>
    <div id="errorPage" class="pt-[40px] h-[calc(100vh-290px)] bg-gray-50">
      <div id="wrapPage" class="max-w-xl mx-auto">
        <h2 class="mb-2 text-center">Halaman tidak ditemukan</h2>
        <p class="p-4 mb-4 text-center">{{ props.error?.message }}</p>
        <button
          class="w-auto mx-auto max-w-xs btn-flk-hollow"
          @click="backToHome"
        >
          Kembali ke halaman utama
        </button>
      </div>
    </div>
  </NuxtLayout>
</template>
