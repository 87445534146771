import { type FetchOptions } from "ofetch";
import { type AsyncDataOptions } from "#app";

import { v4 as uid } from "uuid";
// import HmacSHA256 from "crypto-js/hmac-sha256";

// locals
import FetchFactory from "../factory";

// https://api.dev.useflik.com/checkout/v1/platform/cart/f63a6b30-245f-46cf-a54a-32f9ac30ab2d

type IPaymentGroup = {
  status: string;
  data: null | Array<PaymentGroup>;
  errors: string[];
};

type PaymentResponse = { status: string; data: any; errors: string[] };

class PaymentModule extends FetchFactory<IPaymentGroup> {
  [x: string]: any;

  async getList(
    queryParam: string = "hdo=true&hdg=true",
    asyncDataOptions?: AsyncDataOptions<IPaymentGroup>,
  ) {
    const { PAYMENTSVC } = useRuntimeConfig().public;
    const request_id = uid();

    const { accessToken, loggedIn } = useAuthStore();
    const authorization = `Bearer ${accessToken}`;
    const headers = {
      "X-Request-ID": request_id,
      authorization,
    };

    if (loggedIn) Object.assign(headers, { authorization });

    return await useAsyncData(async () => {
      const fetchOptions: FetchOptions<"json"> = {
        headers: headers,
      };
      return await this.call(
        "GET",
        `${PAYMENTSVC}/v1/pym/list?${queryParam}`,
        undefined, // body
        fetchOptions,
      ).catch((err) => err);
    }, asyncDataOptions);
  }

  async setDefault(
    payload: any,
    asyncDataOptions?: AsyncDataOptions<PaymentResponse>,
  ) {
    const { SHOPPERSVC } = useRuntimeConfig().public;
    const request_id = uid();

    const { accessToken, loggedIn } = useAuthStore();
    const authorization = `Bearer ${accessToken}`;
    const headers = {
      "X-Request-ID": request_id,
      authorization,
    };

    if (loggedIn) Object.assign(headers, { authorization });

    return await useAsyncData(async () => {
      const fetchOptions: FetchOptions<"json"> = {
        headers: headers,
      };
      return await this.call(
        "PUT",
        `${SHOPPERSVC}/v1/shopper/payment/set-default`,
        payload,
        fetchOptions,
      ).catch((err) => err);
    }, asyncDataOptions);
  }

  async add(
    payload: any,
    asyncDataOptions?: AsyncDataOptions<PaymentResponse>,
  ) {
    const { SHOPPERSVC } = useRuntimeConfig().public;
    const request_id = uid();

    const { accessToken, loggedIn } = useAuthStore();
    const authorization = `Bearer ${accessToken}`;
    const headers = {
      "X-Request-ID": request_id,
      authorization,
    };

    // Set billing_address as null IF shopper doesn't have address
    // WHEN did it happen? IF a new shopper tries to pay his invoice-cart.
    const shopper = useShopperStore();
    if (shopper.default_address === "") {
      payload.billing_address = null;
    }

    if (loggedIn) Object.assign(headers, { authorization });

    return await useAsyncData(async () => {
      const fetchOptions: FetchOptions<"json"> = {
        headers: headers,
      };
      return await this.call(
        "POST",
        `${SHOPPERSVC}/v1/shopper/payment`,
        payload,
        fetchOptions,
      ).catch((err) => err);
    }, asyncDataOptions);
  }

  deletePayment(
    id: string,
    asyncDataOptions?: AsyncDataOptions<PaymentResponse>,
  ) {
    // return this.$axios.$delete(
    //   `${SHOPPERSVC}/v1/shopper/payment?token_id=${id}`
    // )

    const { SHOPPERSVC } = useRuntimeConfig().public;
    const request_id = uid();

    const { accessToken } = useAuthStore();
    const authorization = `Bearer ${accessToken}`;
    const headers = {
      "X-Request-ID": request_id,
      authorization,
    };

    return useAsyncData(async () => {
      const fetchOptions: FetchOptions<"json"> = {
        headers: headers,
      };
      return await this.call(
        "DELETE",
        `${SHOPPERSVC}/v1/shopper/payment?token_id=${id}`,
        undefined,
        fetchOptions,
      ).catch((err) => err);
    }, asyncDataOptions);
  }

  async getPaymentBalance(
    tokenID: string,
    asyncDataOptions?: AsyncDataOptions<PaymentResponse>,
  ) {
    const { SHOPPERSVC } = useRuntimeConfig().public;
    const request_id = uid();

    const { accessToken, loggedIn } = useAuthStore();
    const authorization = `Bearer ${accessToken}`;
    const headers = {
      "X-Request-ID": request_id,
      authorization,
    };

    if (loggedIn) Object.assign(headers, { authorization });

    return await useAsyncData(async () => {
      const fetchOptions: FetchOptions<"json"> = {
        headers: headers,
      };
      return await this.call(
        "GET",
        `${SHOPPERSVC}/v1/shopper/payment/latest_balance?token_id=${tokenID}`,
        undefined,
        fetchOptions,
      ).catch((err) => err);
    }, asyncDataOptions);
  }
}

export default PaymentModule;
