import validate from "/runner/_work/checkout-web/checkout-web/node_modules/.pnpm/nuxt@3.10.2_@parcel+watcher@2.4.0_@types+node@20.11.19_encoding@0.1.13_eslint@8.57.0_optionat_jsukvjbxjfuzu333n4ytpbvp54/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/runner/_work/checkout-web/checkout-web/node_modules/.pnpm/nuxt@3.10.2_@parcel+watcher@2.4.0_@types+node@20.11.19_encoding@0.1.13_eslint@8.57.0_optionat_jsukvjbxjfuzu333n4ytpbvp54/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  checkcart: () => import("/runner/_work/checkout-web/checkout-web/middleware/checkcart.ts"),
  customdomain: () => import("/runner/_work/checkout-web/checkout-web/middleware/customdomain.ts"),
  guest: () => import("/runner/_work/checkout-web/checkout-web/middleware/guest.ts"),
  onleave: () => import("/runner/_work/checkout-web/checkout-web/middleware/onleave.ts"),
  redirect: () => import("/runner/_work/checkout-web/checkout-web/middleware/redirect.ts"),
  shopper: () => import("/runner/_work/checkout-web/checkout-web/middleware/shopper.ts"),
  "verify-otp": () => import("/runner/_work/checkout-web/checkout-web/middleware/verifyOtp.ts")
}