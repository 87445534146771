import { type FetchOptions } from "ofetch";
import { type AsyncDataOptions } from "#app";

import { v4 as uid } from "uuid";
import HmacSHA256 from "crypto-js/hmac-sha256";

// locals
import FetchFactory from "../factory";

// https://api.dev.useflik.com/checkout/v1/platform/cart/f63a6b30-245f-46cf-a54a-32f9ac30ab2d

type TransactionResponse = { status: string; data: any; errors: string[] };

class TransactionModule extends FetchFactory<TransactionResponse> {
  [x: string]: any;

  postTransaction(
    payload: any,
    asyncDataOptions?: AsyncDataOptions<TransactionResponse>,
  ) {
    const { PAYMENTSVC } = useRuntimeConfig().public;
    const { XSJSX } = useRuntimeConfig().app;
    const { APP_ID } = useRuntimeConfig().app;
    const request_id = uid();
    let platform = "web";
    let amplitudeSessionID: string = Date.now().toString();
    const transaction_from = useCookie("transaction_from"); // format ==> platform-amplitudeSessionID
    console.log("transaction_from:", transaction_from.value);
    if (transaction_from) {
      const val = String(transaction_from.value).split("-");
      if (val.length === 2) {
        platform = val[0];
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        amplitudeSessionID = val[1];
      }
    }

    const strBody = JSON.stringify(payload);
    const signature = HmacSHA256(strBody, XSJSX).toString();
    const { accessToken, loggedIn } = useAuthStore();
    const authorization = `Bearer ${accessToken}`;
    const headers = {
      app_id: APP_ID,
      "X-Request-ID": request_id,
      "X-Signature": signature,
      "X-Client-Type": platform,
      authorization,
    };

    if (loggedIn) Object.assign(headers, { authorization });

    return useAsyncData(async () => {
      const fetchOptions: FetchOptions<"json"> = {
        headers: headers,
      };
      return await this.call(
        "POST",
        `${PAYMENTSVC}/v1/transactions`,
        payload,
        fetchOptions,
      ).catch((err) => err);
    }, asyncDataOptions);
  }

  getDetail(
    id: string,
    asyncDataOptions?: AsyncDataOptions<TransactionResponse>,
  ) {
    const { PAYMENTSVC } = useRuntimeConfig().public;
    const request_id = uid();

    const { accessToken, loggedIn } = useAuthStore();
    const authorization = `Bearer ${accessToken}`;
    const headers = {
      "X-Request-ID": request_id,
      authorization,
    };

    if (loggedIn) Object.assign(headers, { authorization });

    return useAsyncData(async () => {
      const fetchOptions: FetchOptions<"json"> = {
        headers: headers,
      };
      return await this.call(
        "GET",
        `${PAYMENTSVC}/v1/transactions/${id}`,
        undefined, // body
        fetchOptions,
      ).catch((err) => err);
    }, asyncDataOptions);
  }
}

export default TransactionModule;
