import { defineStore } from "pinia";

export const useAddressStore = defineStore("address", {
  state: () => ({
    addToDeleteId: "",
    toEdit: {
      address_id: "",
      address_label: "",
      name: "",
      email: "",
      phone: "",
      status: "",
      type: "",
      address_1: "",
      address_2: "",
      address_3: "",
      address_4: "",
      city: "",
      state_province: "",
      country_code: "",
      postal_code: 0,
      address_note: "",
      lat: 0,
      lng: 0,
    },
  }),
  actions: {
    async saveAddress(payload: any) {
      console.log("saveAddress payload:", payload);
      const { SHOPPERSVC } = useRuntimeConfig().public;
      const { data } = await useFlikApi<ApiResponse>(
        `${SHOPPERSVC}/v1/shopper/address`,
        {
          method: "PUT",
          body: { ...payload },
        },
      );

      // const addrData = data?.value?.data || null;
      const addrStatus = data?.value?.status || null;
      // console.log("saved data:", addrData);

      if (addrStatus === "success") return true;
      else return false;
    },
    async deleteAddress(id: string) {
      const { SHOPPERSVC } = useRuntimeConfig().public;
      const { data } = await useFlikApi<ApiResponse>(
        `${SHOPPERSVC}/v1/shopper/address?address_id=${id}`,
        {
          method: "DELETE",
        },
      );

      const addrData = data?.value?.data || null;
      const addrStatus = data?.value?.status || null;
      console.log("deleteAddress data:", addrData);

      if (addrStatus === "success") return true;
      else return false;
    },
  },
});
