import { defineNuxtPlugin } from "#app";
export default defineNuxtPlugin(() => {
  const s = document.createElement("script");
  s.id = "xendit";
  s.defer = true;
  s.async = true;
  s.type = "text/javascript";
  s.src = "https://js.xendit.co/v1/xendit.min.js";
  // s.src = '/lib/xendit.min.js'
  document.body.appendChild(s);

  console.log("xendit JS from :", s.src);
});
