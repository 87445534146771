import Tracker from "@openreplay/tracker";
import { v4 as uid } from "uuid";

function defaultUserId() {
  return uid();
}

export default defineNuxtPlugin(() => {
  const shopper = useShopperStore();
  const auth = useAuthStore();
  const loggedIn = auth.loggedIn;
  const acc = shopper.account;
  const userId = acc?.phone ? acc.phone : defaultUserId();
  const email = loggedIn ? acc.email : "";
  const name = loggedIn ? acc.first_name + " " + acc.last_name : "";

  const { OPEN_REPLAY_KEY } = useRuntimeConfig().public;
  if (OPEN_REPLAY_KEY !== "" && !("__OPENREPLAY__" in window)) {
    const trackerConfig: any = {
      projectKey: OPEN_REPLAY_KEY,
      ingestPoint: "https://openreplay.useflik.com/ingest",
      __DISABLE_SECURE_MODE: process.env.NODE_ENV === "development",
      obscureTextNumbers: false,
      obscureTextEmails: false,
      capturePerformance: true,
      capturePageLoadTimings: true,
      onStart: ({ sessionID }: { sessionID: string }) =>
        console.log("OpenReplay tracker started with session: ", sessionID),
      network: {
        sessionTokenHeader: false,
        ignoreHeaders: [],
        capturePayload: true,
      },
    };

    console.log("openReplayTrack trackerConfig:", trackerConfig);
    const tracker = new Tracker(trackerConfig);
    console.log("tracker:", tracker);

    if (!tracker.isActive()) {
      tracker.setUserID(userId);
      tracker.start({
        userID: userId,
        metadata: {
          email: email,
          fullname: name,
          appversion: "2.0.0",
        },
      });
    }
  }
});
