import { defineStore } from "pinia";
import { useNotification } from "@kyvg/vue3-notification";

type IErrData = { data: null; errors: string[]; status: string };

export const useAuthStore = defineStore("auth", {
  state: () => ({
    loggedIn: false,
    phone: "",
    otpExpiresAt: 0,
    otpCode: "",
    accessToken: "",
    refreshToken: "",
    otpChannel: "whatsapp",
    resendOtp: false,
    otpValidating: false,
    guestLoginOpt: {
      isEligible: true,
      showAllOptions: true,
      loginByPhone: false,
      loginAsGuest: false,
    },
  }),
  actions: {
    setOtpValidating(value: boolean) {
      this.otpValidating = value;
    },
    setLoggedIn(value: boolean) {
      this.loggedIn = value;
    },
    setOtpChannel(value: string) {
      this.otpChannel = value.toLowerCase();
    },
    setOtpCode(value: string) {
      this.otpCode = value;
    },
    setResendOtp(value: boolean) {
      this.resendOtp = value;
    },
    setOtpExpiry(value: number) {
      this.otpExpiresAt = value;
    },
    setAccessToken(value: string) {
      this.accessToken = value;
    },
    setRefreshToken(value: string) {
      this.refreshToken = value;
    },
    clearOtpPhone() {
      this.phone = "";
    },
    setOtpPhone(value: string) {
      this.phone = value;
    },
    setEligibleLoginAsGuest(value: boolean) {
      this.guestLoginOpt.isEligible = value;
      this.guestLoginOpt.showAllOptions = value;
    },
    async otpSend(channel = "whatsapp") {
      const { $api: api } = useNuxtApp();
      console.log("this.phone", this.phone);
      this.setResendOtp(false);
      this.setOtpChannel(channel);
      const params = {
        phone: this.phone,
        channel,
      };
      const options = { server: false };
      const { data: auth, status } = await api.auth.otpSend(params, options);
      const authResp = auth.value;
      const authData = authResp?.data;
      const reqStatus = status.value;
      console.log("otpSend authResp:", authResp);
      if (reqStatus === "success" && authData) {
        this.setOtpExpiry(authData.expires_at);
        return true;
      } else {
        return false;
      }
    },
    backToShowAllOptions() {
      this.guestLoginOpt.isEligible = true;
      this.guestLoginOpt.showAllOptions = true;
      this.guestLoginOpt.loginByPhone = false;
      this.guestLoginOpt.loginAsGuest = false;
    },
    showLoginByPhoneForm() {
      this.guestLoginOpt.isEligible = true;
      this.guestLoginOpt.loginByPhone = true;
      this.guestLoginOpt.showAllOptions = false;
      this.guestLoginOpt.loginAsGuest = false;
    },
    showLoginAsGuestForm() {
      this.guestLoginOpt.isEligible = true;
      this.guestLoginOpt.loginAsGuest = true;
      this.guestLoginOpt.showAllOptions = false;
      this.guestLoginOpt.loginByPhone = false;
    },
    async otpValidate() {
      const { $api: api } = useNuxtApp();
      console.log("this.phone", this.phone);
      this.setOtpValidating(true);
      const params = {
        phone: this.phone,
        otp: this.otpCode,
        guest_shopper_id: useShopperStore().account._id,
      };
      const options = {
        server: false,
      };
      // const {
      //   data: auth,
      //   status,
      //   error,
      // } = await api.auth.otpValidate(params, options);

      const resp = await api.auth.otpValidate(params, options);

      console.log("resp:", resp);

      const authData = resp.data.value?.data;
      const reqStatus = resp.status.value;
      const statusCode = resp.error.value?.statusCode;

      if (reqStatus === "error" || statusCode === 400) {
        const errData = resp.error.value?.data as IErrData;
        const errors = errData.errors;

        console.log("authData:", authData);
        console.log("errData:", errData);
        console.log("reqStatus:", reqStatus);
        console.log("statusCode:", statusCode);

        if (errors.length > 0) {
          let errMsg = "OTP error";
          if (errors[0] === "Invalid OTP") {
            errMsg = "Kode OTP yang dimasukkan salah";
            useNotification().notify({
              text: errMsg,
              type: "error",
            });
          }
          this.setOtpValidating(false);
          this.setLoggedIn(false);
          return false;
        }
      }

      console.log("authData:", authData);
      if (reqStatus === "success" && authData) {
        this.setOtpExpiry(0);
        this.setAccessToken(authData.access_token);
        this.setRefreshToken(authData.refresh_token);
        this.setOtpValidating(false);
        this.setLoggedIn(true);
        return true;
      }

      if (reqStatus === "error") {
        this.setOtpValidating(false);
        this.setLoggedIn(false);
        useNotification().notify({
          text: "OTP error",
          type: "error",
        });
        return false;
      }
    },
    doLogout() {
      this.$reset();
      useCartStore().$reset();
      useTransactionStore().$reset();
      usePromoStore().$reset();
      usePromoStore().appliedReward = [];
      usePromoStore().selectedReward = [];
      useShippingStore().$reset();
      useShopperStore().$reset();
      useUiStore().$reset();
      useUiStore().notifPotentialReward = false;
    },
  },
  persist: [
    {
      key: "nfauth",
      paths: ["loggedIn", "accessToken", "refreshToken"],
      storage: persistedState.cookiesWithOptions({
        maxAge: 60 * 60 * 24 * 30,
      }),
    },
    {
      key: "nfauth",
      paths: ["phone", "otpExpiresAt", "resendOtp"],
      storage: persistedState.localStorage,
    },
  ],
});
