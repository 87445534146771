import { type FetchOptions } from "ofetch";
import { type AsyncDataOptions } from "#app";

import { v4 as uid } from "uuid";
import HmacSHA256 from "crypto-js/hmac-sha256";

// locals
import FetchFactory from "../factory";

type ICart = {
  status: string;
  data: null | Cart;
  statusCode: number;
};

// https://api.dev.useflik.com/checkout/v1/platform/cart/f63a6b30-245f-46cf-a54a-32f9ac30ab2d

class CartModule extends FetchFactory<ICart> {
  [x: string]: any;

  getGeneralCartByID(id: string, asyncDataOptions?: AsyncDataOptions<ICart>) {
    const { CARTSVC } = useRuntimeConfig().public;
    const request_id = uid();

    const { accessToken, loggedIn } = useAuthStore();
    const authorization = `Bearer ${accessToken}`;
    const headers = {
      "X-Request-ID": request_id,
    };

    if (loggedIn) Object.assign(headers, { authorization });

    return useAsyncData(async () => {
      const fetchOptions: FetchOptions<"json"> = {
        headers: headers,
      };
      return await this.call(
        "GET",
        `${CARTSVC}/v1/cart?id=${id}`,
        undefined, // body
        fetchOptions,
      ).catch((err) => err);
    }, asyncDataOptions);
  }

  getCart(asyncDataOptions?: AsyncDataOptions<ICart>) {
    const { CARTSVC } = useRuntimeConfig().public;
    const request_id = uid();
    const { APP_KEY, APP_SECRET } = useRuntimeConfig().app;
    const signature = HmacSHA256(request_id + APP_SECRET, APP_KEY).toString();

    const { accessToken, loggedIn } = useAuthStore();
    const authorization = `Bearer ${accessToken}`;
    const headers = {
      "X-Request-ID": request_id,
      "X-Signature": signature,
      authorization,
    };

    if (loggedIn) Object.assign(headers, { authorization });

    return useAsyncData(async () => {
      const fetchOptions: FetchOptions<"json"> = {
        headers: headers,
      };
      return await this.call(
        "GET",
        `${CARTSVC}/v1/cart/shopper`,
        undefined, // body
        fetchOptions,
      ).catch((err) => err);
    }, asyncDataOptions);
  }

  postPlatformCartItem(
    cartId: string,
    payload: UpdatedPlatformCart,
    asyncDataOptions?: AsyncDataOptions<ICart>,
  ) {
    const { CARTSVC } = useRuntimeConfig().public;
    const request_id = uid();
    const { accessToken, loggedIn } = useAuthStore();
    const { APP_KEY, APP_SECRET } = useRuntimeConfig().app;
    const bodyString = JSON.stringify(payload);
    const signature = HmacSHA256(
      bodyString + request_id + APP_SECRET,
      APP_KEY,
    ).toString();
    const authorization = `Bearer ${accessToken}`;

    const headers = {
      "X-App-Id": APP_SECRET,
      "X-Request-ID": request_id,
      "X-Signature": signature,
      authorization,
    };

    if (loggedIn) Object.assign(headers, { authorization });

    return useAsyncData(async () => {
      const fetchOptions: FetchOptions<"json"> = {
        headers: headers,
      };
      return await this.call(
        "POST",
        `${CARTSVC}/v1/platform/cart/${cartId}/items`,
        payload, // body
        fetchOptions,
      ).catch((err) => err);
    }, asyncDataOptions);
  }

  replaceGeneralCartItems(
    payload: ReplaceCartItemRequest,
    asyncDataOptions?: AsyncDataOptions<ICart>,
  ) {
    const { CARTSVC } = useRuntimeConfig().public;
    const request_id = uid();
    const { accessToken, loggedIn } = useAuthStore();
    const authorization = `Bearer ${accessToken}`;
    const headers = {
      "X-Request-ID": request_id,
      authorization,
    };

    if (loggedIn) Object.assign(headers, { authorization });

    return useAsyncData(async () => {
      const fetchOptions: FetchOptions<"json"> = {
        headers: headers,
      };
      return await this.call(
        "POST",
        `${CARTSVC}/v1/cart/items/replace`,
        payload, // body
        fetchOptions,
      ).catch((err) => err);
    }, asyncDataOptions);
  }

  postItem(payload: UpdatedCart, asyncDataOptions?: AsyncDataOptions<ICart>) {
    const { CARTSVC } = useRuntimeConfig().public;
    const request_id = uid();
    const { accessToken, loggedIn } = useAuthStore();
    const authorization = `Bearer ${accessToken}`;
    const headers = {
      "X-Request-ID": request_id,
      authorization,
    };

    if (loggedIn) Object.assign(headers, { authorization });

    return useAsyncData(async () => {
      const fetchOptions: FetchOptions<"json"> = {
        headers: headers,
      };
      return await this.call(
        "POST",
        `${CARTSVC}/v1/cart/items`,
        payload, // body
        fetchOptions,
      ).catch((err) => err);
    }, asyncDataOptions);
  }

  deletePlatformCartItem(
    cartId: string,
    payload: RemovePlatformCartItem,
    asyncDataOptions?: AsyncDataOptions<ICart>,
  ) {
    const { CARTSVC } = useRuntimeConfig().public;
    const request_id = uid();
    const { accessToken, loggedIn } = useAuthStore();
    const { APP_KEY, APP_SECRET } = useRuntimeConfig().app;
    const bodyString = JSON.stringify(payload);
    const signature = HmacSHA256(
      bodyString + request_id + APP_SECRET,
      APP_KEY,
    ).toString();
    const authorization = `Bearer ${accessToken}`;

    const headers = {
      "X-App-Id": APP_SECRET,
      "X-Request-ID": request_id,
      "X-Signature": signature,
      authorization,
    };

    if (loggedIn) Object.assign(headers, { authorization });

    return useAsyncData(async () => {
      const fetchOptions: FetchOptions<"json"> = {
        headers: headers,
      };
      return await this.call(
        "DELETE",
        `${CARTSVC}/v1/platform/cart/${cartId}/items`,
        payload, // body
        fetchOptions,
      ).catch((err) => err);
    }, asyncDataOptions);
  }

  deleteItem(payload: any, asyncDataOptions?: AsyncDataOptions<ICart>) {
    const { CARTSVC } = useRuntimeConfig().public;
    const request_id = uid();
    const { accessToken, loggedIn } = useAuthStore();
    const authorization = `Bearer ${accessToken}`;
    const headers = {
      "X-Request-ID": request_id,
      authorization,
    };

    if (loggedIn) Object.assign(headers, { authorization });

    return useAsyncData(async () => {
      const fetchOptions: FetchOptions<"json"> = {
        headers: headers,
      };
      return await this.call(
        "DELETE",
        `${CARTSVC}/v1/cart/items`,
        payload, // body
        fetchOptions,
      ).catch((err) => err);
    }, asyncDataOptions);
  }

  getCartPlatform(
    id: string,
    city: string | undefined,
    isShared: boolean,
    asyncDataOptions?: AsyncDataOptions<ICart>,
  ) {
    const { CARTSVC } = useRuntimeConfig().public;
    const request_id = uid();
    const { APP_KEY, APP_SECRET } = useRuntimeConfig().app;
    const signature = HmacSHA256(request_id + APP_SECRET, APP_KEY).toString();

    const { accessToken, loggedIn } = useAuthStore();
    const authorization = `Bearer ${accessToken}`;
    const headers = {
      "X-App-Id": APP_SECRET,
      "X-Request-ID": request_id,
      "X-Signature": signature,
      authorization,
    };

    if (loggedIn) Object.assign(headers, { authorization });

    let query = city ? `?city=${city}` : "";
    if (isShared) {
      query += query ? "&shared=true" : "?shared=true";
    }
    console.log("getCartPlatform id:", id);
    console.log("getCartPlatform query:", query);

    return useAsyncData(async () => {
      const fetchOptions: FetchOptions<"json"> = {
        headers: headers,
      };
      return await this.call(
        "GET",
        `${CARTSVC}/v1/platform/cart/${id}${query}`,
        undefined, // body
        fetchOptions,
      ).catch((err) => err);
    }, asyncDataOptions);
  }

  getAffiliateConfig(asyncDataOptions?: AsyncDataOptions<ICart>) {
    const { CARTSVC } = useRuntimeConfig().public;
    const { accessToken } = useAuthStore();
    const authorization = `Bearer ${accessToken}`;
    const headers = {
      authorization,
    };

    return useAsyncData(async () => {
      const fetchOptions: FetchOptions<"json"> = {
        headers: headers,
      };
      return await this.call(
        "GET",
        `${CARTSVC}/v1/affiliate/config`,
        undefined, // body
        fetchOptions,
      ).catch((err) => err);
    }, asyncDataOptions);
  }
}

export default CartModule;
