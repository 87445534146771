import { default as _91_46_46_46product_93hr1Je9BIZBMeta } from "/runner/_work/checkout-web/checkout-web/pages/[...product].vue?macro=true";
import { default as _91merchant_93sSqDPlcyN8Meta } from "/runner/_work/checkout-web/checkout-web/pages/[merchant].vue?macro=true";
import { default as addEXlGz3pwmBMeta } from "/runner/_work/checkout-web/checkout-web/pages/address/add.vue?macro=true";
import { default as blankzZuzWgAJGcMeta } from "/runner/_work/checkout-web/checkout-web/pages/blank.vue?macro=true";
import { default as _91id_93mCAgSdBbkCMeta } from "/runner/_work/checkout-web/checkout-web/pages/cart/[id].vue?macro=true";
import { default as deveFTA7PFywrMeta } from "/runner/_work/checkout-web/checkout-web/pages/dev.vue?macro=true";
import { default as indexCZ6koXpUpfMeta } from "/runner/_work/checkout-web/checkout-web/pages/index.vue?macro=true";
import { default as logoutyzCkOh0g8SMeta } from "/runner/_work/checkout-web/checkout-web/pages/logout.vue?macro=true";
import { default as order_45success3aFRKvsq0FMeta } from "/runner/_work/checkout-web/checkout-web/pages/order-success.vue?macro=true";
import { default as _91oid_93kPUw1LPnrdMeta } from "/runner/_work/checkout-web/checkout-web/pages/order/[oid].vue?macro=true";
import { default as otpnANWn8Mq7hMeta } from "/runner/_work/checkout-web/checkout-web/pages/otp.vue?macro=true";
import { default as _91id_93YASfcFRWSSMeta } from "/runner/_work/checkout-web/checkout-web/pages/payment/[id].vue?macro=true";
import { default as addm2pFJ7QhRHMeta } from "/runner/_work/checkout-web/checkout-web/pages/payment/card/add.vue?macro=true";
import { default as index9vtGZIdHuYMeta } from "/runner/_work/checkout-web/checkout-web/pages/payment/card/index.vue?macro=true";
import { default as danan6nrya9WuOMeta } from "/runner/_work/checkout-web/checkout-web/pages/payment/dana.vue?macro=true";
import { default as _91id_93jl7zCYe90ZMeta } from "/runner/_work/checkout-web/checkout-web/pages/payment/detail/[id].vue?macro=true";
import { default as gopay_45qrXEcsBzbRMoMeta } from "/runner/_work/checkout-web/checkout-web/pages/payment/gopay-qr.vue?macro=true";
import { default as gopaysDf7LFPqBCMeta } from "/runner/_work/checkout-web/checkout-web/pages/payment/gopay.vue?macro=true";
import { default as dana88ofkJu0QdMeta } from "/runner/_work/checkout-web/checkout-web/pages/payment/howto/dana.vue?macro=true";
import { default as ovoQYiDECFaIjMeta } from "/runner/_work/checkout-web/checkout-web/pages/payment/howto/ovo.vue?macro=true";
import { default as shopeepayQckoaRMmcfMeta } from "/runner/_work/checkout-web/checkout-web/pages/payment/howto/shopeepay.vue?macro=true";
import { default as landingF9g0QCfMSgMeta } from "/runner/_work/checkout-web/checkout-web/pages/payment/landing.vue?macro=true";
import { default as ovoXfV0mobQnNMeta } from "/runner/_work/checkout-web/checkout-web/pages/payment/ovo.vue?macro=true";
import { default as _91id_93UX6YFbdrkPMeta } from "/runner/_work/checkout-web/checkout-web/pages/payment/processing/[id].vue?macro=true";
import { default as qrgHQV46aSoJMeta } from "/runner/_work/checkout-web/checkout-web/pages/payment/qr.vue?macro=true";
import { default as shopeepayMO7J7bb688Meta } from "/runner/_work/checkout-web/checkout-web/pages/payment/shopeepay.vue?macro=true";
import { default as danaqDnLyawGQ7Meta } from "/runner/_work/checkout-web/checkout-web/pages/payment/terms/dana.vue?macro=true";
import { default as gopaynrLgJMQI8kMeta } from "/runner/_work/checkout-web/checkout-web/pages/payment/terms/gopay.vue?macro=true";
import { default as ovocACvK9uIpAMeta } from "/runner/_work/checkout-web/checkout-web/pages/payment/terms/ovo.vue?macro=true";
import { default as shopeepayQ7j5KRTCwdMeta } from "/runner/_work/checkout-web/checkout-web/pages/payment/terms/shopeepay.vue?macro=true";
import { default as va4Iuadx3ivTMeta } from "/runner/_work/checkout-web/checkout-web/pages/payment/va.vue?macro=true";
import { default as processing82QWVWeNqGMeta } from "/runner/_work/checkout-web/checkout-web/pages/processing.vue?macro=true";
import { default as productsSY6Zfu2CeaMeta } from "/runner/_work/checkout-web/checkout-web/pages/products.vue?macro=true";
import { default as profileb2SYOPQx6kMeta } from "/runner/_work/checkout-web/checkout-web/pages/profile.vue?macro=true";
import { default as indexp9V2p5fxycMeta } from "~/pages/index.vue?macro=true";
import { default as landingkABBaUqzcDMeta } from "~/pages/payment/landing.vue?macro=true";
import { default as order_45successGrwLBKx9hTMeta } from "~/pages/order-success.vue?macro=true";
export default [
  {
    name: _91_46_46_46product_93hr1Je9BIZBMeta?.name ?? "product",
    path: _91_46_46_46product_93hr1Je9BIZBMeta?.path ?? "/:product(.*)*",
    meta: _91_46_46_46product_93hr1Je9BIZBMeta || {},
    alias: _91_46_46_46product_93hr1Je9BIZBMeta?.alias || [],
    redirect: _91_46_46_46product_93hr1Je9BIZBMeta?.redirect,
    component: () => import("/runner/_work/checkout-web/checkout-web/pages/[...product].vue").then(m => m.default || m)
  },
  {
    name: _91merchant_93sSqDPlcyN8Meta?.name ?? "merchant",
    path: _91merchant_93sSqDPlcyN8Meta?.path ?? "/:merchant()",
    meta: _91merchant_93sSqDPlcyN8Meta || {},
    alias: _91merchant_93sSqDPlcyN8Meta?.alias || [],
    redirect: _91merchant_93sSqDPlcyN8Meta?.redirect,
    component: () => import("/runner/_work/checkout-web/checkout-web/pages/[merchant].vue").then(m => m.default || m)
  },
  {
    name: addEXlGz3pwmBMeta?.name ?? "address-add",
    path: addEXlGz3pwmBMeta?.path ?? "/address/add",
    meta: addEXlGz3pwmBMeta || {},
    alias: addEXlGz3pwmBMeta?.alias || [],
    redirect: addEXlGz3pwmBMeta?.redirect,
    component: () => import("/runner/_work/checkout-web/checkout-web/pages/address/add.vue").then(m => m.default || m)
  },
  {
    name: blankzZuzWgAJGcMeta?.name ?? "blank",
    path: blankzZuzWgAJGcMeta?.path ?? "/blank",
    meta: blankzZuzWgAJGcMeta || {},
    alias: blankzZuzWgAJGcMeta?.alias || [],
    redirect: blankzZuzWgAJGcMeta?.redirect,
    component: () => import("/runner/_work/checkout-web/checkout-web/pages/blank.vue").then(m => m.default || m)
  },
  {
    name: _91id_93mCAgSdBbkCMeta?.name ?? "cart-id",
    path: _91id_93mCAgSdBbkCMeta?.path ?? "/cart/:id()",
    meta: _91id_93mCAgSdBbkCMeta || {},
    alias: _91id_93mCAgSdBbkCMeta?.alias || [],
    redirect: _91id_93mCAgSdBbkCMeta?.redirect,
    component: () => import("/runner/_work/checkout-web/checkout-web/pages/cart/[id].vue").then(m => m.default || m)
  },
  {
    name: deveFTA7PFywrMeta?.name ?? "dev",
    path: deveFTA7PFywrMeta?.path ?? "/dev",
    meta: deveFTA7PFywrMeta || {},
    alias: deveFTA7PFywrMeta?.alias || [],
    redirect: deveFTA7PFywrMeta?.redirect,
    component: () => import("/runner/_work/checkout-web/checkout-web/pages/dev.vue").then(m => m.default || m)
  },
  {
    name: indexCZ6koXpUpfMeta?.name ?? "index",
    path: indexCZ6koXpUpfMeta?.path ?? "/",
    meta: indexCZ6koXpUpfMeta || {},
    alias: indexCZ6koXpUpfMeta?.alias || [],
    redirect: indexCZ6koXpUpfMeta?.redirect,
    component: () => import("/runner/_work/checkout-web/checkout-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: logoutyzCkOh0g8SMeta?.name ?? "logout",
    path: logoutyzCkOh0g8SMeta?.path ?? "/logout",
    meta: logoutyzCkOh0g8SMeta || {},
    alias: logoutyzCkOh0g8SMeta?.alias || [],
    redirect: logoutyzCkOh0g8SMeta?.redirect,
    component: () => import("/runner/_work/checkout-web/checkout-web/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: order_45success3aFRKvsq0FMeta?.name ?? "order-success",
    path: order_45success3aFRKvsq0FMeta?.path ?? "/order-success",
    meta: order_45success3aFRKvsq0FMeta || {},
    alias: order_45success3aFRKvsq0FMeta?.alias || [],
    redirect: order_45success3aFRKvsq0FMeta?.redirect,
    component: () => import("/runner/_work/checkout-web/checkout-web/pages/order-success.vue").then(m => m.default || m)
  },
  {
    name: _91oid_93kPUw1LPnrdMeta?.name ?? "order-oid",
    path: _91oid_93kPUw1LPnrdMeta?.path ?? "/order/:oid()",
    meta: _91oid_93kPUw1LPnrdMeta || {},
    alias: _91oid_93kPUw1LPnrdMeta?.alias || [],
    redirect: _91oid_93kPUw1LPnrdMeta?.redirect,
    component: () => import("/runner/_work/checkout-web/checkout-web/pages/order/[oid].vue").then(m => m.default || m)
  },
  {
    name: otpnANWn8Mq7hMeta?.name ?? "otp",
    path: otpnANWn8Mq7hMeta?.path ?? "/otp",
    meta: otpnANWn8Mq7hMeta || {},
    alias: otpnANWn8Mq7hMeta?.alias || [],
    redirect: otpnANWn8Mq7hMeta?.redirect,
    component: () => import("/runner/_work/checkout-web/checkout-web/pages/otp.vue").then(m => m.default || m)
  },
  {
    name: _91id_93YASfcFRWSSMeta?.name ?? "payment-id",
    path: _91id_93YASfcFRWSSMeta?.path ?? "/payment/:id()",
    meta: _91id_93YASfcFRWSSMeta || {},
    alias: _91id_93YASfcFRWSSMeta?.alias || [],
    redirect: _91id_93YASfcFRWSSMeta?.redirect,
    component: () => import("/runner/_work/checkout-web/checkout-web/pages/payment/[id].vue").then(m => m.default || m)
  },
  {
    name: addm2pFJ7QhRHMeta?.name ?? "payment-card-add",
    path: addm2pFJ7QhRHMeta?.path ?? "/payment/card/add",
    meta: addm2pFJ7QhRHMeta || {},
    alias: addm2pFJ7QhRHMeta?.alias || [],
    redirect: addm2pFJ7QhRHMeta?.redirect,
    component: () => import("/runner/_work/checkout-web/checkout-web/pages/payment/card/add.vue").then(m => m.default || m)
  },
  {
    name: index9vtGZIdHuYMeta?.name ?? "payment-card",
    path: index9vtGZIdHuYMeta?.path ?? "/payment/card",
    meta: index9vtGZIdHuYMeta || {},
    alias: index9vtGZIdHuYMeta?.alias || [],
    redirect: index9vtGZIdHuYMeta?.redirect,
    component: () => import("/runner/_work/checkout-web/checkout-web/pages/payment/card/index.vue").then(m => m.default || m)
  },
  {
    name: danan6nrya9WuOMeta?.name ?? "payment-dana",
    path: danan6nrya9WuOMeta?.path ?? "/payment/dana",
    meta: danan6nrya9WuOMeta || {},
    alias: danan6nrya9WuOMeta?.alias || [],
    redirect: danan6nrya9WuOMeta?.redirect,
    component: () => import("/runner/_work/checkout-web/checkout-web/pages/payment/dana.vue").then(m => m.default || m)
  },
  {
    name: _91id_93jl7zCYe90ZMeta?.name ?? "payment-detail-id",
    path: _91id_93jl7zCYe90ZMeta?.path ?? "/payment/detail/:id()",
    meta: _91id_93jl7zCYe90ZMeta || {},
    alias: _91id_93jl7zCYe90ZMeta?.alias || [],
    redirect: _91id_93jl7zCYe90ZMeta?.redirect,
    component: () => import("/runner/_work/checkout-web/checkout-web/pages/payment/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: gopay_45qrXEcsBzbRMoMeta?.name ?? "payment-gopay-qr",
    path: gopay_45qrXEcsBzbRMoMeta?.path ?? "/payment/gopay-qr",
    meta: gopay_45qrXEcsBzbRMoMeta || {},
    alias: gopay_45qrXEcsBzbRMoMeta?.alias || [],
    redirect: gopay_45qrXEcsBzbRMoMeta?.redirect,
    component: () => import("/runner/_work/checkout-web/checkout-web/pages/payment/gopay-qr.vue").then(m => m.default || m)
  },
  {
    name: gopaysDf7LFPqBCMeta?.name ?? "payment-gopay",
    path: gopaysDf7LFPqBCMeta?.path ?? "/payment/gopay",
    meta: gopaysDf7LFPqBCMeta || {},
    alias: gopaysDf7LFPqBCMeta?.alias || [],
    redirect: gopaysDf7LFPqBCMeta?.redirect,
    component: () => import("/runner/_work/checkout-web/checkout-web/pages/payment/gopay.vue").then(m => m.default || m)
  },
  {
    name: dana88ofkJu0QdMeta?.name ?? "payment-howto-dana",
    path: dana88ofkJu0QdMeta?.path ?? "/payment/howto/dana",
    meta: dana88ofkJu0QdMeta || {},
    alias: dana88ofkJu0QdMeta?.alias || [],
    redirect: dana88ofkJu0QdMeta?.redirect,
    component: () => import("/runner/_work/checkout-web/checkout-web/pages/payment/howto/dana.vue").then(m => m.default || m)
  },
  {
    name: ovoQYiDECFaIjMeta?.name ?? "payment-howto-ovo",
    path: ovoQYiDECFaIjMeta?.path ?? "/payment/howto/ovo",
    meta: ovoQYiDECFaIjMeta || {},
    alias: ovoQYiDECFaIjMeta?.alias || [],
    redirect: ovoQYiDECFaIjMeta?.redirect,
    component: () => import("/runner/_work/checkout-web/checkout-web/pages/payment/howto/ovo.vue").then(m => m.default || m)
  },
  {
    name: shopeepayQckoaRMmcfMeta?.name ?? "payment-howto-shopeepay",
    path: shopeepayQckoaRMmcfMeta?.path ?? "/payment/howto/shopeepay",
    meta: shopeepayQckoaRMmcfMeta || {},
    alias: shopeepayQckoaRMmcfMeta?.alias || [],
    redirect: shopeepayQckoaRMmcfMeta?.redirect,
    component: () => import("/runner/_work/checkout-web/checkout-web/pages/payment/howto/shopeepay.vue").then(m => m.default || m)
  },
  {
    name: landingF9g0QCfMSgMeta?.name ?? "payment-landing",
    path: landingF9g0QCfMSgMeta?.path ?? "/payment/landing",
    meta: landingF9g0QCfMSgMeta || {},
    alias: landingF9g0QCfMSgMeta?.alias || [],
    redirect: landingF9g0QCfMSgMeta?.redirect,
    component: () => import("/runner/_work/checkout-web/checkout-web/pages/payment/landing.vue").then(m => m.default || m)
  },
  {
    name: ovoXfV0mobQnNMeta?.name ?? "payment-ovo",
    path: ovoXfV0mobQnNMeta?.path ?? "/payment/ovo",
    meta: ovoXfV0mobQnNMeta || {},
    alias: ovoXfV0mobQnNMeta?.alias || [],
    redirect: ovoXfV0mobQnNMeta?.redirect,
    component: () => import("/runner/_work/checkout-web/checkout-web/pages/payment/ovo.vue").then(m => m.default || m)
  },
  {
    name: _91id_93UX6YFbdrkPMeta?.name ?? "payment-processing-id",
    path: _91id_93UX6YFbdrkPMeta?.path ?? "/payment/processing/:id()",
    meta: _91id_93UX6YFbdrkPMeta || {},
    alias: _91id_93UX6YFbdrkPMeta?.alias || [],
    redirect: _91id_93UX6YFbdrkPMeta?.redirect,
    component: () => import("/runner/_work/checkout-web/checkout-web/pages/payment/processing/[id].vue").then(m => m.default || m)
  },
  {
    name: qrgHQV46aSoJMeta?.name ?? "payment-qr",
    path: qrgHQV46aSoJMeta?.path ?? "/payment/qr",
    meta: qrgHQV46aSoJMeta || {},
    alias: qrgHQV46aSoJMeta?.alias || [],
    redirect: qrgHQV46aSoJMeta?.redirect,
    component: () => import("/runner/_work/checkout-web/checkout-web/pages/payment/qr.vue").then(m => m.default || m)
  },
  {
    name: shopeepayMO7J7bb688Meta?.name ?? "payment-shopeepay",
    path: shopeepayMO7J7bb688Meta?.path ?? "/payment/shopeepay",
    meta: shopeepayMO7J7bb688Meta || {},
    alias: shopeepayMO7J7bb688Meta?.alias || [],
    redirect: shopeepayMO7J7bb688Meta?.redirect,
    component: () => import("/runner/_work/checkout-web/checkout-web/pages/payment/shopeepay.vue").then(m => m.default || m)
  },
  {
    name: danaqDnLyawGQ7Meta?.name ?? "payment-terms-dana",
    path: danaqDnLyawGQ7Meta?.path ?? "/payment/terms/dana",
    meta: danaqDnLyawGQ7Meta || {},
    alias: danaqDnLyawGQ7Meta?.alias || [],
    redirect: danaqDnLyawGQ7Meta?.redirect,
    component: () => import("/runner/_work/checkout-web/checkout-web/pages/payment/terms/dana.vue").then(m => m.default || m)
  },
  {
    name: gopaynrLgJMQI8kMeta?.name ?? "payment-terms-gopay",
    path: gopaynrLgJMQI8kMeta?.path ?? "/payment/terms/gopay",
    meta: gopaynrLgJMQI8kMeta || {},
    alias: gopaynrLgJMQI8kMeta?.alias || [],
    redirect: gopaynrLgJMQI8kMeta?.redirect,
    component: () => import("/runner/_work/checkout-web/checkout-web/pages/payment/terms/gopay.vue").then(m => m.default || m)
  },
  {
    name: ovocACvK9uIpAMeta?.name ?? "payment-terms-ovo",
    path: ovocACvK9uIpAMeta?.path ?? "/payment/terms/ovo",
    meta: ovocACvK9uIpAMeta || {},
    alias: ovocACvK9uIpAMeta?.alias || [],
    redirect: ovocACvK9uIpAMeta?.redirect,
    component: () => import("/runner/_work/checkout-web/checkout-web/pages/payment/terms/ovo.vue").then(m => m.default || m)
  },
  {
    name: shopeepayQ7j5KRTCwdMeta?.name ?? "payment-terms-shopeepay",
    path: shopeepayQ7j5KRTCwdMeta?.path ?? "/payment/terms/shopeepay",
    meta: shopeepayQ7j5KRTCwdMeta || {},
    alias: shopeepayQ7j5KRTCwdMeta?.alias || [],
    redirect: shopeepayQ7j5KRTCwdMeta?.redirect,
    component: () => import("/runner/_work/checkout-web/checkout-web/pages/payment/terms/shopeepay.vue").then(m => m.default || m)
  },
  {
    name: va4Iuadx3ivTMeta?.name ?? "payment-va",
    path: va4Iuadx3ivTMeta?.path ?? "/payment/va",
    meta: va4Iuadx3ivTMeta || {},
    alias: va4Iuadx3ivTMeta?.alias || [],
    redirect: va4Iuadx3ivTMeta?.redirect,
    component: () => import("/runner/_work/checkout-web/checkout-web/pages/payment/va.vue").then(m => m.default || m)
  },
  {
    name: processing82QWVWeNqGMeta?.name ?? "processing",
    path: processing82QWVWeNqGMeta?.path ?? "/processing",
    meta: processing82QWVWeNqGMeta || {},
    alias: processing82QWVWeNqGMeta?.alias || [],
    redirect: processing82QWVWeNqGMeta?.redirect,
    component: () => import("/runner/_work/checkout-web/checkout-web/pages/processing.vue").then(m => m.default || m)
  },
  {
    name: productsSY6Zfu2CeaMeta?.name ?? "products",
    path: productsSY6Zfu2CeaMeta?.path ?? "/products",
    meta: productsSY6Zfu2CeaMeta || {},
    alias: productsSY6Zfu2CeaMeta?.alias || [],
    redirect: productsSY6Zfu2CeaMeta?.redirect,
    component: () => import("/runner/_work/checkout-web/checkout-web/pages/products.vue").then(m => m.default || m)
  },
  {
    name: profileb2SYOPQx6kMeta?.name ?? "profile",
    path: profileb2SYOPQx6kMeta?.path ?? "/profile",
    meta: profileb2SYOPQx6kMeta || {},
    alias: profileb2SYOPQx6kMeta?.alias || [],
    redirect: profileb2SYOPQx6kMeta?.redirect,
    component: () => import("/runner/_work/checkout-web/checkout-web/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: indexp9V2p5fxycMeta?.name ?? "cart",
    path: indexp9V2p5fxycMeta?.path ?? "/cart",
    meta: indexp9V2p5fxycMeta || {},
    alias: indexp9V2p5fxycMeta?.alias || [],
    redirect: indexp9V2p5fxycMeta?.redirect,
    component: () => import("~/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexp9V2p5fxycMeta?.name ?? "login",
    path: indexp9V2p5fxycMeta?.path ?? "/login",
    meta: indexp9V2p5fxycMeta || {},
    alias: indexp9V2p5fxycMeta?.alias || [],
    redirect: indexp9V2p5fxycMeta?.redirect,
    component: () => import("~/pages/index.vue").then(m => m.default || m)
  },
  {
    name: landingkABBaUqzcDMeta?.name ?? "ewallet-linking-:status",
    path: landingkABBaUqzcDMeta?.path ?? "/ewallet/linking/:status",
    meta: landingkABBaUqzcDMeta || {},
    alias: landingkABBaUqzcDMeta?.alias || [],
    redirect: landingkABBaUqzcDMeta?.redirect,
    component: () => import("~/pages/payment/landing.vue").then(m => m.default || m)
  },
  {
    name: order_45successGrwLBKx9hTMeta?.name ?? "order-success",
    path: order_45successGrwLBKx9hTMeta?.path ?? "/order-success",
    meta: order_45successGrwLBKx9hTMeta || {},
    alias: order_45successGrwLBKx9hTMeta?.alias || [],
    redirect: order_45successGrwLBKx9hTMeta?.redirect,
    component: () => import("~/pages/order-success.vue").then(m => m.default || m)
  },
  {
    name: order_45successGrwLBKx9hTMeta?.name ?? "order-failed",
    path: order_45successGrwLBKx9hTMeta?.path ?? "/order-failed",
    meta: order_45successGrwLBKx9hTMeta || {},
    alias: order_45successGrwLBKx9hTMeta?.alias || [],
    redirect: order_45successGrwLBKx9hTMeta?.redirect,
    component: () => import("~/pages/order-success.vue").then(m => m.default || m)
  }
]