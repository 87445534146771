import { defineNuxtPlugin } from "#app";
// eslint-disable-next-line import/no-named-as-default
import VueTelInput from "vue-tel-input";
import "~/assets/css/custom-vue-tel-input.css";
const options = {
  mode: "international",
  defaultCountry: "ID",
  onlyCountries: [
    "AF",
    "AU",
    "BD",
    "BN",
    "BR",
    "CA",
    "CN",
    "DE",
    "DK",
    "EG",
    "FR",
    "GB",
    "ID",
    "IN",
    "IT",
    "JO",
    "JP",
    "KH",
    "KR",
    "LA",
    "LB",
    "LY",
    "MG",
    "MM",
    "MY",
    "NL",
    "NO",
    "NZ",
    "PH",
    "PK",
    "PS",
    "RU",
    "SA",
    "SE",
    "SG",
    "TH",
    "TL",
    "TR",
    "TW",
    "US",
    "VN",
    "YE",
  ],
  validCharactersOnly: true,
  disabledFetchingCountry: true,
  enabledFlags: true,
  autoFormat: true,
  inputOptions: {
    autofocus: true,
    autocomplete: true,
    showDialCode: false,
    required: true,
    id: "inputPhone",
    name: "inputPhone",
    maxlength: 18,
    placeholder: "Nomor handphone",
    invalidMsg: "Nomor handphone tidak valid",
  },
  dropdownOptions: {
    showSearchBox: true,
    showFlags: true,
    showDialCodeInSelection: false,
    width: "300",
  },
};
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(VueTelInput, options);
});
