import { defineStore } from "pinia";

export enum AmpEventEnum {
  ampPageViewed = "Page Viewed",
  ampSignupCompleted = "Signup Completed",
  ampBtnClicked = "Button Clicked",
  ampCheckoutInit = "Checkout Initiated",
  ampCheckoutCompleted = "Checkout Completed",
  ampLoginCompleted = "Login Completed",
}

export const useAmplitudeStore = defineStore("amplitude", {
  state: () => ({
    ampInstance: null,
    eventProps: {
      platform: "",
      signup_at: 0,
      login_at: 0,
      page_name: "",
      page_variant: "",
      page_viewed_at: 0,
      user_id: "",
      device_id: "",
      button_name: "",
      button_variant: "",
      button_sub_variant: "",
      button_clicked_at: 0,
      checkout_type: "",
      merchant: "",
      trx_id: "",
      order_id: "",
      total_amount: 0,
      total_discount: 0,
      total_payment: 0,
      checkout_initiated_at: 0,
      checkout_completed_at: 0,
      payment_method: "",
      shipping_method: "",
      selected_courier: [] as string[],
    },
  }),

  actions: {
    reset() {
      this.$reset();
    },

    setDefaultProp(platform: string, userId: string, deviceId: string) {
      this.eventProps.platform = platform;
      this.eventProps.user_id = userId;
      if (deviceId.length > 0) {
        this.eventProps.device_id = deviceId;
      }
    },

    setEventProps(eventProps: any) {
      // this.eventProps = eventProps;
      const keys: any = Object.keys(eventProps);
      keys.forEach((key: string) => {
        console.log("key:", key, "value:", eventProps[key]);
      });
    },

    setPropForPageViewed(
      pageName: string,
      pageVariant: string,
      merchant?: string,
    ) {
      this.eventProps.button_name = "";
      this.eventProps.button_variant = "";
      this.eventProps.button_sub_variant = "";
      this.eventProps.page_name = pageName;
      this.eventProps.page_variant = pageVariant;
      this.eventProps.page_viewed_at = Date.now();
      if (merchant) {
        this.eventProps.merchant = merchant;
      }
      const paymentMethod = useShopperStore().defPayment;
      const shipCourier = useShippingStore().selectedCourier || [];
      console.log("setPropForPageViewed paymentMethod:", paymentMethod);
      if (paymentMethod) {
        this.eventProps.payment_method = paymentMethod.type;
      }
      if (hasLength(shipCourier)) {
        const couriers = shipCourier.map((courier: any) => courier.id);
        this.eventProps.selected_courier = couriers;
      }
    },

    setPropForBtnClicked(
      buttonName: string,
      buttonVariant: string,
      buttonSubVariant: string,
    ) {
      this.eventProps.button_name = buttonName;
      this.eventProps.button_variant = buttonVariant;
      this.eventProps.button_sub_variant = buttonSubVariant;
      this.eventProps.button_clicked_at = Date.now();
    },

    setPropForLoginCompleted() {
      this.eventProps.login_at = Date.now();
    },

    setPropForSignupCompleted() {
      this.eventProps.signup_at = Date.now();
    },

    setPropForCheckoutInitiated(
      checkoutType: string,
      merchant: string,
      totalAmount: number,
    ) {
      this.eventProps.checkout_type = checkoutType;
      this.eventProps.merchant = merchant;
      this.eventProps.total_amount = totalAmount;
      this.eventProps.checkout_initiated_at = Date.now();
    },

    setPropForCheckoutCompleted(
      checkoutType: string,
      merchant: string,
      trxId: string,
      orderId: string,
      totalAmount: number,
      totalDiscount: number,
      totalPayment: number,
      paymentMethod: string,
      shippingMethod: string,
    ) {
      this.eventProps.checkout_type = checkoutType;
      this.eventProps.merchant = merchant;
      this.eventProps.trx_id = trxId;
      this.eventProps.order_id = orderId;
      this.eventProps.total_amount = totalAmount;
      this.eventProps.total_discount = totalDiscount;
      this.eventProps.total_payment = totalPayment;
      this.eventProps.payment_method = paymentMethod;
      this.eventProps.shipping_method = shippingMethod;
      this.eventProps.checkout_completed_at = Date.now();
    },
  },

  persist: [
    {
      key: "nftracker_amplitude",
      paths: ["eventProps"],
      storage: persistedState.localStorage,
    },
  ],
});
