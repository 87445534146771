// [FILE]: repository/modules/products.ts

// 3rd's
import { type FetchOptions } from "ofetch";
import { type AsyncDataOptions } from "#app";

// locals
import FetchFactory from "../factory";

class ProductsModule extends FetchFactory<ProductDemo[]> {
  private RESOURCE = "https://fakestoreapi.com/products?limit=8";

  /**
   * Return the products as array
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async getProducts(asyncDataOptions?: AsyncDataOptions<ProductDemo[]>) {
    return await useAsyncData(() => {
      const fetchOptions: FetchOptions<"json"> = {
        headers: {
          "Accept-Language": "en-US",
        },
      };
      return this.call(
        "GET",
        `${this.RESOURCE}`,
        undefined, // body
        fetchOptions,
      );
    }, asyncDataOptions);
  }
}

export default ProductsModule;
