import { defineStore } from "pinia";
import { validate as isUUID } from "uuid";
import { useNotification } from "@kyvg/vue3-notification";
import { defaultAddress, defaultPayment } from "@/types/var";
import { useAuthStore } from "@/stores/auth";

export const useShopperStore = defineStore("shopper", {
  state: () => ({
    isLoading: false,
    balanceLoading: false,
    default_address: "",
    default_payment: "",
    created_at: 0,
    defPayInGroup: false,
    flags: {
      is_guest: false,
    },
    defPayment: null as any,
    defAddress: {
      address_id: "",
      address_label: "",
      address_1: "",
      postal_code: "",
    } as any,
    hasAddress: false,
    hasAccount: false,
    account: {
      _id: "",
      email: "",
      phone: "",
      first_name: "",
      last_name: "",
    },
    ewalletBalance: [
      {
        balance: 0,
        token_id: "",
      },
    ],
    saved_payments: [],
    shipping_address: [] as Address[],
  }),
  getters: {
    hasPayment: (state) => {
      if (state.saved_payments.length > 0) return true;
      return false;
    },
    hasPaymentOf: (state) => (type: string) => {
      return state.saved_payments.some((sp: any) => {
        // console.log("DEBUG hasPaymentOf:", sp.type, type);
        return sp.type === type;
      });
    },
    firstName: (state) => {
      if (state.account.first_name !== "") {
        return state.account.first_name;
      }
      return "";
    },
    lastName: (state) => {
      if (state.account.last_name !== "") {
        return state.account.last_name;
      }
      return "";
    },
    myPhone: (state) => {
      if (state.account.phone !== "") {
        return state.account.phone;
      }
      return "";
    },
    myEmail: (state) => {
      if (state.account.email !== "") {
        return state.account.email;
      }
      return "";
    },
    activePaymentDetail: (state) => {
      // GET CURRENT ACTIVE PAYMENT DETAIL
      let objPayment = {};
      if (state.default_payment.length > 0) {
        state.saved_payments.some((sp: any) => {
          if (sp.token_id === state.default_payment) {
            objPayment = sp;
            return true;
          }
          return false;
        });
      }
      return objPayment;
    },
    // getActivePaymentDetail: (state) => {
    //   // GET CURRENT ACTIVE PAYMENT DETAIL
    //   let objPayment = {};
    //   if (state.default_payment.length > 0) {
    //     state.saved_payments.some((sp: any) => {
    //       if (sp.token_id === state.default_payment) {
    //         objPayment = sp;
    //       }
    //     });
    //   }
    //   return objPayment;
    // },
    getActivePaymentByType: (state) => (type: string) => {
      // GET ACTIVE PAYMENT DETAIL BY TYPE
      let objPayment = {};
      if (state.default_payment.length > 0) {
        state.saved_payments.some((sp: any) => {
          if (sp.type === type) {
            objPayment = sp;
            return true;
          }
          return false;
        });
      }
      return objPayment;
    },
    // get ewallet balance from store
    getEwalletBalance: (state) => (tokenID: string) => {
      state.ewalletBalance.forEach((el) => {
        console.log("DEBUG: get balance from store:", tokenID, el.balance);
        if (el.token_id === tokenID) {
          return el.balance;
        }
      });
      return 0;
    },
  },
  actions: {
    setAccount(params: any) {
      this.$patch({ account: params, hasAccount: true });
    },
    setShopperLoading(status: boolean) {
      this.isLoading = status;
    },
    resetDefaultAddress() {
      this.defAddress = defaultAddress;
    },
    async setShopperData(params: any) {
      this.setShopperLoading(true);
      delete params.active_devices;
      delete params.flags.repeat_shopper;
      delete params.updated_at;

      if (
        isUUID(params?.account._id) &&
        params?.account.email?.length > 0 &&
        params?.account.first_name?.length > 0
      ) {
        this.hasAccount = true;
      }

      let defAddress = defaultAddress;
      let defPayment = null;

      if (params?.shipping_address?.length === 0 && params?.shipping_address) {
        this.hasAddress = false;
      }
      if (params.shipping_address.length === 1) {
        defAddress = params.shipping_address[0];
        this.hasAddress = true;
      }
      if (params.shipping_address.length > 1) {
        this.hasAddress = true;
        const findAddress = params.shipping_address.find(
          (a: any) => a.address_id === params.default_address,
        );
        if (findAddress) defAddress = findAddress;
      }

      const paymentList = usePaymentStore().listGroup;

      if (hasLength(params.saved_payments) && hasLength(paymentList)) {
        const findPayment: any = params.saved_payments.find(
          (p: any) => p.token_id === params.default_payment,
        );

        console.log("setShopperData findPayment:", findPayment);

        if (findPayment) {
          const paymentChildren = getChildren(
            findPayment?.type.toLowerCase(),
            paymentList,
          );
          console.log("setShopperData paymentChildren:", paymentChildren);

          if (paymentChildren?.id) {
            Object.assign(findPayment, { ...paymentChildren });
            this.defPayInGroup = true;
          } else {
            this.defPayInGroup = false;
          }

          const ewallets = ["ovo", "shopeepay", "dana"];

          if (findPayment?.dynamic_attr) delete findPayment.dynamic_attr;

          delete findPayment.billing_address;
          // delete findPayment.dynamic_attr;
          if (findPayment?.type !== "card" && findPayment.card_info)
            delete findPayment.card_info;
          if (!ewallets.includes(findPayment.type) && findPayment.ewallet_info)
            delete findPayment.ewallet_info;

          console.log("setShopperData findPayment:", findPayment);

          defPayment = findPayment;

          if (ewallets.includes(findPayment.type)) {
            await usePaymentStore().getEwalletBalance(findPayment.token_id);
          }
        }
        usePaymentStore().isLoading = false;
      } else {
        defPayment = defaultPayment;
      }

      // delete params.shipping_address;
      // console.log("setShopperData defPayment:", defPayment);
      this.$patch({ defPayment, defAddress, ...params });
      this.setShopperLoading(false);
    },
    // add or update ewallet balance on store
    addUpdatePaymentBalance(tokenID: string, balance: number) {
      let isNew = true;
      this.ewalletBalance.forEach((el) => {
        if (el.token_id === tokenID) {
          // update balance
          el.balance = balance;
          isNew = false;
          console.log(
            "DEBUG: payment balance updated",
            el.token_id,
            el.balance,
          );
        }
      });
      if (isNew) {
        this.ewalletBalance.push({ token_id: tokenID, balance: balance });
        console.log("DEBUG: payment balance added", tokenID, balance);
      }
    },
    async getShopper() {
      const auth = useAuthStore();
      if (auth.loggedIn) {
        const { $api: api } = useNuxtApp();
        this.setShopperLoading(true);
        const options = {
          server: false,
        };
        const { data: shopper } = await api.shopper.getShopper("-", options);
        const shopperResp = shopper.value;
        const shopperData = shopperResp?.data;
        if (shopperData) {
          this.setShopperData(shopperData);
        }
      }
      this.setShopperLoading(false);
    },
    async createGuestShopper(payload: any) {
      this.setShopperLoading(true);

      const { SHOPPERSVC } = useRuntimeConfig().public;
      const { data, error } = await useFlikApi<GuestShopperResponse>(
        `${SHOPPERSVC}/v1/shopper/guest`,
        {
          method: "POST",
          body: { ...payload },
        },
      );

      const { data: authData, status } = data?.value || {};

      if (status === "success" && authData?.access_token) {
        const auth = useAuthStore();
        auth.setAccessToken(authData?.access_token);
        const shopper = useShopperStore();
        shopper.flags.is_guest = true;
        console.log("createGuestShopper payload:", payload);
        shopper.account.email = payload.email;
        shopper.account._id = authData?.shopper_id;
        const name = payload.fullname.split(" ");
        console.log("createGuestShopper name:", name);
        if (name.length > 0) {
          shopper.account.first_name = name[0];
          shopper.account.last_name = name[1];
        }
        auth.setLoggedIn(true);
        this.setShopperLoading(false);
        return true;
      }

      const { errors } = error?.value?.data || {};

      if (errors && hasLength(errors)) {
        useNotification().notify({
          text: errors[0],
          type: "error",
        });
      }

      this.setShopperLoading(false);
      return false;
    },
    async putAccount(params: any) {
      this.setShopperLoading(true);
      const { $api: api } = useNuxtApp();
      const options = { server: false };
      const { data: shopper, status } = await api.shopper.putAccount(
        params,
        options,
      );
      const shopperResp = shopper.value;
      const shopperData = shopperResp?.data || null;
      const errors = shopperData?.errors || [];
      const reqStatus = status.value;
      if (reqStatus === "success" && errors.length === 0) {
        this.setAccount(params);
        this.setShopperLoading(false);
        return true;
      }
      if (reqStatus === "success" && errors.length > 0) {
        useNotification().notify({ text: errors[0], type: "error" });
        this.setShopperLoading(false);
        return false;
      }
      if (reqStatus === "error") {
        useNotification().notify({
          text: "Error update account",
          type: "error",
        });

        this.setShopperLoading(false);
        return false;
      }
    },
    async putAddress(params: any) {
      this.setShopperLoading(true);
      const { $api: api } = useNuxtApp();
      const options = { server: false };
      const { data: shopper, status } = await api.shopper.putAddress(
        Object.assign(params, { is_default: true }),
        options,
      );
      const shopperResp = shopper.value;
      const shopperData = shopperResp?.data || null;
      const errors = shopperData?.errors || [];
      const reqStatus = status.value;
      if (reqStatus === "success" && errors.length === 0) {
        this.defAddress = params;
        this.setShopperLoading(false);
        return true;
      }
      if (reqStatus === "success" && errors.length > 0) {
        useNotification().notify({
          text: errors[0],
          type: "error",
        });
        this.setShopperLoading(false);
        return false;
      }
      if (reqStatus === "error") {
        useNotification().notify({
          text: "Error update account",
          type: "error",
        });
        this.setShopperLoading(false);
        return false;
      }
    },
    async addPayment(params: any) {
      this.setShopperLoading(true);
      const { $api: api } = useNuxtApp();
      const options = { server: false };
      const { data: resp, status } = await api.payment.add(
        Object.assign(params, { is_default: true }),
        options,
      );
      const result = resp.value;
      const addPaymentData = result?.data || null;
      const errors = addPaymentData?.errors || [];
      const reqStatus = status.value;
      if (reqStatus === "success" && errors.length === 0) {
        this.setShopperLoading(false);
        return addPaymentData;
      }
      if (reqStatus === "success" && errors.length > 0) {
        useNotification().notify({
          text: errors[0],
          type: "error",
        });
        this.setShopperLoading(false);
        return null;
      }
    },
    async syncPaymentBalance(tokenID: string) {
      this.balanceLoading = true;

      const { $api: api } = useNuxtApp();
      const options = { server: false };
      const { data: resp, status } = await api.payment.getPaymentBalance(
        tokenID,
        options,
      );

      const result = resp?.value?.data || null;
      const errors = resp?.value?.errors || [];
      if (status?.value === "success" && errors.length === 0) {
        this.addUpdatePaymentBalance(tokenID, result?.balance || 0);
        this.balanceLoading = false;

        return result;
      }
      if (errors.length > 0) {
        useNotification().notify({
          text: errors[0],
          type: "error",
        });
        this.balanceLoading = false;

        return null;
      }
    },
    async deletePayment(tokenID: string) {
      this.setShopperLoading(true);
      const { $api: api } = useNuxtApp();
      const options = { server: false };
      const { data: resp, status } = await api.payment.deletePayment(
        tokenID,
        options,
      );

      const errors = resp?.value?.errors || [];
      if (status?.value === "success" && errors.length === 0) {
        // rebuild ewallet ballance
        this.ewalletBalance = this.ewalletBalance.filter(
          (row) => row.token_id !== tokenID,
        );
        // rebuild saved payments
        this.saved_payments = this.saved_payments.filter(
          (row) => row.token_id !== tokenID,
        );
        this.setShopperLoading(false);
        return true;
      }
      if (errors.length > 0) {
        const errorMessage = errors.join(", ");
        this.setShopperLoading(false);
        throw new Error(errorMessage);
      }
    },
  },
  persist: [
    {
      key: "nfshopper",
      paths: [
        "account",
        "defAddress",
        "defPayment",
        "default_address",
        "default_payment",
        "saved_payments",
      ],
      storage: persistedState.localStorage,
    },
  ],
});
