import { defineNuxtPlugin } from "#app";
import parser from "ua-parser-js";
import { load as FingerprintJSLoad } from "@fingerprintjs/fingerprintjs";

export default defineNuxtPlugin(() => {
  const uaParser = parser(navigator.userAgent);
  const browserInfo = useVisitorInfoStore();
  browserInfo.setBrowserInfo({
    browserName: uaParser.browser.name,
    browserVersion: uaParser.browser.version,
    browseEngine: uaParser.engine.name,
    deviceVendor: uaParser.device.vendor,
    deviceModel: uaParser.device.model,
    deviceType: uaParser.device.type,
    osName: uaParser.os.name,
    osVersion: uaParser.os.version,
    cpu: uaParser.cpu.architecture,
    userAgent: navigator.userAgent,
  });

  // Initialize an agent at application startup.
  const fpPromise = FingerprintJSLoad();
  (async () => {
    // Get the visitor identifier when you need it.
    const fp = await fpPromise;
    const result = await fp.get();
    browserInfo.setVisitorId(result.visitorId);
  })();
});
