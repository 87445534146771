import { defineNuxtPlugin } from "#app";
// import VueGoogleMaps from "@fawmi/vue-google-maps";
import VueGoogleMaps from "@fawmi/vue-google-maps/src/main.js";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(VueGoogleMaps, {
    load: {
      key: useRuntimeConfig().public.GOOGLE_MAPS_API_KEY,
      libraries: "places",
      async: true,
    },
  });
});
